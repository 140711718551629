import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Loading, useNotify } from 'react-admin';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import {
	useGetAppraisalDetailsQuery,
	useGetAppraisalUsersDetailsForExportQuery,
	useGetAppraisalUsersDetailsQuery,
	useRemoveUserFromAppraisalMutation,
} from '../../../generated/graphql';
import {
	APPRAISAL_CYCLE_PATH,
	PMS_APPRAISAL_STATUS,
	PMS_DATE_FORMAT,
} from '../constant';
import {
	appraisalDetailsStyle,
	pmsDatagridStyle,
	pmsViewDetailsStyle,
} from '../pms.styles';

import dayjs from 'dayjs';
import {
	Box,
	ThemeProvider,
	TextField as TextComponent,
	Button,
	CircularProgress,
	Tooltip,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { DataGridTheme } from '../pmsTheme';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import Loader from '../SharedComponents/Loader.component';
import IncludeEmployeeDrawer from './IncludeEmployeeDrawer.component';
import {
	EMPLOYEES_REMOVED_SUCCESSFULLY,
	viewGoalsInitialState,
} from '../constant';
import ReviewerForm from './ReviewerForm.component';
import PMSBreadcrumb from '../SharedComponents/PMSBreadcrumb.component';
import GoalMappingForm from '../SharedComponents/GoalMappingForm.component';
import { head, isEmpty } from 'lodash';
import { globalStyles, modalFormStyle } from '../../../Layout/styles';
import AppraisalCycleInsights from './AppraisalCycleInsights.component';
import { getExperience } from '../../../Utils/date-time.util';
import ViewGoals from '../SharedComponents/ViewGoals.component';
import { UserProfileContext } from '../../../App';
import { ViewGoalsDetails } from '../pms.module';
import {
	exportToCsv,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import { APPRAISAL_DETAILS_EXPORT_COLUMNS } from './constant';
import StatusChangeForm from './StatusChangeForm.component';

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText, clearSelectedRowsAndData } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Employees'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
						clearSelectedRowsAndData();
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

interface AppraisalUserList {
	id: any;
	name: string;
	email: string;
	reviewer: string;
	review_status: string;
	userId: any;
	assignedGoalIds: string[];
	allocated_weight: number;
}

function AppraisalDetails() {
	const { dateFormat } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const appraisalDetailsStyles = appraisalDetailsStyle();
	const dataGridStyles = pmsDatagridStyle();
	const formStyles = modalFormStyle();
	const commonStyles = globalStyles();
	const notify = useNotify();
	const pmsDetailsStyle = pmsViewDetailsStyle();

	// States
	const [searchedText, setSearchedText] = useState<string>('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [data, setData] = useState<AppraisalUserList[]>([]);
	const [selectedRowData, setSelectedRowData] = useState<AppraisalUserList[]>(
		[]
	);
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [selectedRowIds, setSelectedRowIds] = useState<number[][]>([[]]);
	const [isIncludeEmployeeDrawerOpen, setIsIncludeEmployeeDrawerOpen] =
		useState(false);
	const [isReviewerFormOpen, setIsReviewerFormOpen] = useState(false);
	const [isStatusChangeFormOpen, setIsStatusChangeFormOpen] = useState(false);
	const [isGoalMappingFormShown, setIsGoalMappingFormShown] = useState(false);
	const [isAppraisalInsightsShown, setIsAppraisalInsightsShown] =
		useState(false);
	const [selectedViewGoals, setSelectedViewGoals] = useState<ViewGoalsDetails>(
		viewGoalsInitialState
	);
	const [isViewGoalsModalShown, setIsViewGoalsModalShown] = useState(false);
	const [sortFilter, setSortFilter] = useState<any[]>([
		{ user: { full_name: 'asc' } },
	]);

	// Refs
	const prevPageRef = useRef<null | number>(null);

	// API CALLS
	const {
		data: appraisalDetail,
		loading: isAppraisalDetailLoading,
		refetch: refetchAppraisalDetails,
	} = useGetAppraisalDetailsQuery({
		variables: {
			appraisalCycleId: id,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: appraisalUsers,
		loading: isUserListLoading,
		refetch: refetchAppraisalUsers,
	} = useGetAppraisalUsersDetailsQuery({
		variables: {
			appraisalCycleId: id,
			searchText: `%${searchedText}%`,
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			order: sortFilter,
		},
		fetchPolicy: 'network-only',
		onCompleted: (data) => updateSelectedRowsData(data),
	});

	const { data: appraisalUsersForExport } =
		useGetAppraisalUsersDetailsForExportQuery({
			variables: {
				appraisalCycleId: id,
				searchText: `%${searchedText}%`,
				order: sortFilter,
			},
			fetchPolicy: 'network-only',
		});
	const [excludeUser, { loading: isRemoveAppraisalLoading }] =
		useRemoveUserFromAppraisalMutation();

	const updateSelectedRowsData = (data: any) => {
		if (!data) {
			return;
		}
		const userData =
			data?.pms_pms_appraisal_user_mapping?.map((pmsUserMapping: any) => {
				return {
					id: pmsUserMapping.id,
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status: pmsUserMapping.pms_user_appraisal_status.label,
					userId: pmsUserMapping.user.id,
					assignedGoalIds:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping: any) =>
									goalMapping.user_id === pmsUserMapping.user.id
							)
							.map((user: any) => user.goal_id),
					allocated_weight:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping: any) =>
									goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum: any, value: any) => {
								return value.weightage + sum;
							}, 0) || 0,
					goals: {
						goals:
							pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings.filter(
								(goalMapping: any) =>
									goalMapping.user_id === pmsUserMapping.user.id
							),
						user: {
							user: pmsUserMapping.user,
							reviewer: pmsUserMapping.userByReviewer,
							userAppraisalStatus:
								pmsUserMapping.pms_user_appraisal_status.value,
						},
					},
					data: {
						id: pmsUserMapping.id,
						name: pmsUserMapping.user.full_name || '- -',
						email: pmsUserMapping.user.email,
						reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
						review_status: pmsUserMapping.pms_user_appraisal_status.label,
					},
				};
			}) || [];

		if (page !== prevPageRef.current) {
			const displayData = userData;
			let selectedRows =
				selectedRowIds[page] &&
				selectedRowIds[page].length > 0 &&
				displayData.length > 0
					? selectedRowIds[page].map((rowIndex: number) => {
							return displayData[rowIndex];
					  })
					: [];
			setSelectedRowData(selectedRows);
			prevPageRef.current = page;
		}
	};

	useEffect(() => {
		if (!appraisalUsers) return;
		const userData = appraisalUsers.pms_pms_appraisal_user_mapping.map(
			(pmsUserMapping) => {
				return {
					id: pmsUserMapping.id,
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status: pmsUserMapping.pms_user_appraisal_status.label,
					userId: pmsUserMapping.user.id,
					assignedGoalIds:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.map((user) => user.goal_id),
					allocated_weight:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum, value) => {
								return value.weightage + sum;
							}, 0) || 0,
					goals: {
						goals:
							pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							),
						user: {
							user: pmsUserMapping.user,
							reviewer: pmsUserMapping.userByReviewer,
							userAppraisalStatus:
								pmsUserMapping.pms_user_appraisal_status.value,
						},
					},
					data: {
						id: pmsUserMapping.id,
						name: pmsUserMapping.user.full_name || '- -',
						email: pmsUserMapping.user.email,
						reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
						review_status: pmsUserMapping.pms_user_appraisal_status.label,
					},
				};
			}
		);
		setData(userData);
	}, [appraisalUsers]);

	const columns = [
		{
			name: 'data',
			label: 'data',
			options: {
				filter: true,
				viewColumns: false,
				display: false,
			},
		},
		{
			name: 'name',
			label: 'NAME',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'email',
			label: 'EMAIL',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'reviewer',
			label: 'REVIEWER',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'review_status',
			label: 'REVIEW STATUS',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'goals',
			label: 'GOALS',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const joinDate =
						value.user?.user.user_type === 'employee'
							? value.user?.user?.employee?.join_date || null
							: value.user?.user.contractors[0]?.join_date || null;

					const priorExperience =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.prior_experience
							: '00:00:00';

					const jobLevel =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.job_level?.level || '- -'
							: value.user?.user.contractors[0]?.job_level?.level || '- -';

					const department =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.department?.name || '- -'
							: value.user?.user.contractors[0]?.department?.name || '- -';

					const status =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.employee_status?.label || '- -'
							: value.user?.user.contractors[0]?.contractorStatus?.label ||
							  '- -';

					const userDetails = {
						userName: value.user?.user.full_name || '- -',
						experience: joinDate
							? getExperience({
									join_date: joinDate,
									prior_experience: priorExperience,
							  }).totalExp
							: '0',
						reviewer: value.user.reviewer?.full_name || '- -',
						jobLevel: jobLevel || '- -',
						department: department || '- -',
						status: status || '- -',
						profilePic: value.user.user?.profile_pic || '',
						userAppraisalStatus: value.user.userAppraisalStatus || '',
					};

					const goalsDetails = value.goals.map((goal: any) => {
						return {
							id: goal.id,
							name: goal?.pms_master_goal?.name || '- -',
							description: goal?.pms_master_goal?.description || '- -',
							weightage: goal?.weightage,
							startDate: dayjs(goal?.start_date).format(dateFormat),
							endDate: dayjs(goal?.end_date).format(dateFormat),
						};
					});

					return (
						<p
							className={dataGridStyles.link}
							onClick={() => {
								setSelectedViewGoals({
									userDetails: userDetails,
									goals: goalsDetails,
								});

								setIsViewGoalsModalShown(true);
							}}
						>{`View Goals(${value.goals.length})`}</p>
					);
				},
			},
		},
		{
			name: 'allocated_weight',
			label: 'ALLOCATED WEIGHT',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <p>{`${value}%`}</p>;
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							<Button
								disabled={
									isRemoveAppraisalLoading ||
									getAppraisalStatusForDisable() ||
									(selectedRowIds[page] && selectedRowIds[page].length > 0)
								}
								className={
									isRemoveAppraisalLoading ||
									getAppraisalStatusForDisable() ||
									(selectedRowIds[page] && selectedRowIds[page].length > 0)
										? dataGridStyles.disabledExcludeButton
										: dataGridStyles.excludeButton
								}
								onClick={() => {
									const pmsUserMappingId = tableMeta.rowData[0].id;
									const excludeUserId = getUsers()?.find(
										(usrMapping) => usrMapping.id === pmsUserMappingId
									)?.userId;
									if (excludeUserId) {
										handleExcludeOneUser(excludeUserId);
									}
								}}
							>
								Exclude
							</Button>
						</>
					);
				},
			},
		},
	];

	const clearSelectedRowsAndData = () => {
		setSelectedRowData([]);
		setSelectedRowIds([[]]);
	};

	const getAppraisalStatusForDisable = () => {
		if (!appraisalDetail) {
			return false;
		}
		const appraisalStatusValue =
			appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.pms_appraisal_status
				?.value;

		return appraisalStatusValue === PMS_APPRAISAL_STATUS.APPRAISAL_CLOSED
			? true
			: false;
	};

	const options: MUIDataTableOptions = {
		filter: false,
		search: false,
		selectableRowsHeader: getAppraisalStatusForDisable() ? false : true,
		selectableRowsHideCheckboxes: getAppraisalStatusForDisable() || false,
		rowsSelected:
			selectedRowIds[page] && selectedRowIds[page].length > 0
				? selectedRowIds[page]
				: [],
		print: false,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [5, 10, 20, 30],
		download: false,
		page: page,
		serverSide: true,
		viewColumns: false,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		count: appraisalUsers?.usersCount.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
			clearSelectedRowsAndData();
		},
		onTableChange: (action: string, tableState: any) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				case 'sort': {
					clearSelectedRowsAndData();
					break;
				}
				default:
					return;
			}
		},
		onRowSelectionChange: (
			currentRowsSelected: any,
			allRowsSelected: any,
			rowsSelected: any
		) => {
			handleRowSelect(currentRowsSelected, allRowsSelected, rowsSelected);
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'name': {
					setSortFilter([{ user: { full_name: direction } }]);
					break;
				}
				case 'email': {
					setSortFilter([{ user: { email: direction } }]);
					break;
				}
				case 'reviewer': {
					setSortFilter([{ userByReviewer: { full_name: direction } }]);
					break;
				}
				case 'review_status': {
					setSortFilter([{ pms_user_appraisal_status: { label: direction } }]);
					break;
				}
				default: {
					setSortFilter([]);
				}
			}
		},
	};

	const updateSelectedRowIds = (
		currentlySelectedRowIds: number[][],
		pageNumber: number,
		selectedRowIdsForCurrentPage: number[]
	) => {
		const newSelectedRowIds = currentlySelectedRowIds.slice();
		while (newSelectedRowIds.length <= pageNumber) {
			newSelectedRowIds.push([]);
		}
		newSelectedRowIds[pageNumber] = selectedRowIdsForCurrentPage;

		return newSelectedRowIds;
	};

	const handleRowSelect = (
		currentRowsSelected: any,
		allRowsSelected: any,
		rowsSelectedData: any
	) => {
		const selected = allRowsSelected.map((item: any) => item.index);
		setSelectedRowIds((prev) => {
			const result = updateSelectedRowIds(prev, page, selected);
			return result;
		});
		const displayData = getUsers();
		if (displayData) {
			let selectedRows = rowsSelectedData.map((dataIndex: any) => {
				return displayData[dataIndex];
			});

			setSelectedRowData(selectedRows);
		}
	};

	const getUsers = () => {
		if (!appraisalUsers) return;
		const userData = appraisalUsers.pms_pms_appraisal_user_mapping.map(
			(pmsUserMapping) => {
				return {
					id: pmsUserMapping.id,
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status: pmsUserMapping.pms_user_appraisal_status.label,
					userId: pmsUserMapping.user.id,
					assignedGoalIds:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.map((user) => user.goal_id),
					allocated_weight:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum, value) => {
								return value.weightage + sum;
							}, 0) || 0,
					goals:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings.filter(
							(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
						).length || 0,
				};
			}
		);
		return userData;
	};

	const getAppraisalUserIds = () => {
		if (
			!appraisalUsers ||
			!appraisalUsers?.userIdsInAppraisal ||
			appraisalUsers?.userIdsInAppraisal?.length === 0
		) {
			return [];
		}

		return appraisalUsers?.userIdsInAppraisal.map((user) => user.user_id);
	};

	function customToolbar() {
		return (
			<div className={dataGridStyles.toolbarContainer}>
				<>
					<p>{selectedRowIds[page]?.length} Row(s) selected</p>
				</>
				<Box display={'flex'} alignItems={'center'}>
					<Button
						disabled={isRemoveAppraisalLoading}
						className={dataGridStyles.buttonSm}
						onClick={() => {
							setIsGoalMappingFormShown(true);
						}}
					>
						Add Goals
					</Button>
					<Button
						disabled={isRemoveAppraisalLoading}
						className={dataGridStyles.outlinedSmButton}
						onClick={() => {
							setIsStatusChangeFormOpen(true);
						}}
					>
						Change Status
					</Button>
					<Button
						disabled={isRemoveAppraisalLoading}
						className={dataGridStyles.outlinedSmButton}
						onClick={() => {
							setIsReviewerFormOpen(true);
						}}
					>
						Change Reviewer
					</Button>
					<Button
						disabled={isRemoveAppraisalLoading}
						className={
							isRemoveAppraisalLoading
								? dataGridStyles.disabledExcludeButton
								: dataGridStyles.excludeButton
						}
						onClick={handleExcludeUser}
						startIcon={isRemoveAppraisalLoading && <Loader />}
					>
						{`Exclude(${selectedRowIds[page]?.length})`}
					</Button>
				</Box>
			</div>
		);
	}

	const handleExcludeUser = () => {
		if (selectedRowData.length === 0) return;

		excludeUser({
			variables: {
				removedUserIds: selectedRowData.map((usr) => usr.userId),
				appraisalCycleId: id,
			},
		}).then((res) => {
			if (!res.errors) {
				refetchAppraisalUsers();
				refetchAppraisalDetails();
				notify(EMPLOYEES_REMOVED_SUCCESSFULLY);
				setSearchedText('');
				clearSelectedRowsAndData();
			}
		});
	};

	const handleExcludeOneUser = (userId: any) => {
		if (!userId) return;

		excludeUser({
			variables: {
				removedUserIds: [userId],
				appraisalCycleId: id,
			},
		}).then((res) => {
			if (!res.errors) {
				refetchAppraisalUsers();
				refetchAppraisalDetails();
				notify(EMPLOYEES_REMOVED_SUCCESSFULLY);
				setSearchedText('');
				clearSelectedRowsAndData();
			}
		});
	};

	const handleExport = () => {
		if (isEmpty(appraisalUsersForExport?.pms_pms_appraisal_user_mapping)) {
			return;
		}

		const apprailsalData =
			appraisalUsersForExport?.pms_pms_appraisal_user_mapping.map(
				(pmsUserMapping) => {
					return [
						pmsUserMapping.user.full_name || '- -',
						pmsUserMapping.user.email,
						pmsUserMapping.userByReviewer?.full_name || '- -',
						pmsUserMapping.pms_user_appraisal_status.label,
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum, value) => {
								return value.weightage + sum;
							}, 0) || 0,
					];
				}
			) || [];
		const exportData = [
			APPRAISAL_DETAILS_EXPORT_COLUMNS,
			...[...(apprailsalData || [])],
		];
		exportToCsv(
			`${appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name}.csv`,
			exportData
		);
	};

	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const onExportClick = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};
	return (
		<>
			<PMSBreadcrumb
				data={[
					{ ...APPRAISAL_CYCLE_PATH },
					{
						name:
							appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name ||
							'Appraisal Cycle Detail',
					},
				]}
				icon={DoubleArrowIcon}
			/>

			<Box className={pmsDetailsStyle.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						!isAppraisalInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsAppraisalInsightsShown(false);
					}}
				>
					Appraisal Details
				</Button>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						isAppraisalInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsAppraisalInsightsShown(true);
					}}
				>
					Insights
				</Button>
			</Box>

			{isAppraisalInsightsShown ? (
				<AppraisalCycleInsights />
			) : (
				<>
					{isAppraisalDetailLoading ? (
						<Loading />
					) : (
						<div className={appraisalDetailsStyles.root}>
							{/* Header */}
							<div className={appraisalDetailsStyles.headerContainer}>
								<div>
									<Tooltip
										title={
											appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name ||
											'- -'
										}
									>
										<p className={appraisalDetailsStyles.header}>
											{appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name ||
												'- -'}
										</p>
									</Tooltip>
								</div>
							</div>
							<div className={appraisalDetailsStyles.headerContainer}>
								<div
									className={appraisalDetailsStyles.flex}
									style={{
										width: '100%',
										justifyContent: 'space-evenly',
									}}
								>
									<div>
										<p className={appraisalDetailsStyles.headerLabel}>Period</p>
										<p className={appraisalDetailsStyles.headerValue}>{`${dayjs(
											appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.start_date
										).format(PMS_DATE_FORMAT)} - ${dayjs(
											appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.end_date
										).format(PMS_DATE_FORMAT)}`}</p>
									</div>
									<div>
										<p className={appraisalDetailsStyles.headerLabel}>Status</p>
										<p className={appraisalDetailsStyles.headerValue}>
											{appraisalDetail?.pms_pms_appraisal_cycle_by_pk
												?.pms_appraisal_status.label || '- -'}
										</p>
									</div>
									<div>
										<p className={appraisalDetailsStyles.headerLabel}>
											No.Of Employees
										</p>
										<p className={appraisalDetailsStyles.headerValue}>
											{appraisalDetail?.pms_pms_appraisal_cycle_by_pk
												?.usersCount.aggregate?.count || 0}
										</p>
									</div>
								</div>
							</div>

							{/* List */}
							<div className={appraisalDetailsStyles.listContainer}>
								<p className={appraisalDetailsStyles.listHeading}>Employees</p>
								<Box
									display='flex'
									justifyContent='space-between'
									marginBottom='20px'
									marginTop='20px'
								>
									<Box
										display='flex'
										alignItems='center'
										sx={{ gridGap: '8px' }}
									>
										<Box>
											<CustomSearchRender
												searchText={searchedText}
												setSearchedText={(val: string) => {
													setSearchedText(val);
													setPage(0);
												}}
												clearSelectedRowsAndData={clearSelectedRowsAndData}
											/>
										</Box>
										{isUserListLoading ||
											(isRemoveAppraisalLoading && (
												<Box>
													<Loader />
												</Box>
											))}
									</Box>

									<Box display='flex' sx={{ gridGap: '8px' }}>
										<Box>
											<Button
												className={
													getAppraisalStatusForDisable()
														? dataGridStyles.disabledExportButton
														: dataGridStyles.exportButton
												}
												onClick={() => {
													setIsIncludeEmployeeDrawerOpen(true);
												}}
												disabled={getAppraisalStatusForDisable()}
											>
												Include Employee
											</Button>
										</Box>
										<Box>
											<Button
												className={
													isEmpty(data) || isButtonDisabled
														? dataGridStyles.disabledExportButton
														: dataGridStyles.exportButton
												}
												endIcon={<ArrowUpwardIcon />}
												onClick={onExportClick}
												disabled={isEmpty(data)}
												startIcon={
													isButtonDisabled && (
														<CircularProgress
															style={{
																height: '20px',
																width: '20px',
																color: '#FFFFFF',
															}}
														/>
													)
												}
											>
												Export
											</Button>
										</Box>
									</Box>
								</Box>
								<>{selectedRowIds[page]?.length > 0 && customToolbar()}</>
								<ThemeProvider theme={DataGridTheme}>
									<MUIDataTable
										title='Appraisal Users'
										data={data}
										columns={columns}
										options={options}
									/>
								</ThemeProvider>
							</div>
							<IncludeEmployeeDrawer
								open={isIncludeEmployeeDrawerOpen}
								close={() => {
									setIsIncludeEmployeeDrawerOpen(false);
								}}
								appraisalUsersIds={getAppraisalUserIds()}
								appraisalCycleId={id}
								onSuccess={() => {
									refetchAppraisalDetails();
									refetchAppraisalUsers();
									setIsIncludeEmployeeDrawerOpen(false);
								}}
							/>
							<ReviewerForm
								open={isReviewerFormOpen}
								onClose={() => {
									setIsReviewerFormOpen(false);
								}}
								onSuccess={() => {
									setSearchedText('');
									refetchAppraisalDetails();
									refetchAppraisalUsers();
									setIsReviewerFormOpen(false);
								}}
								selectedUsersId={selectedRowData.map((usr) => usr.userId)}
							/>
							<StatusChangeForm
								open={isStatusChangeFormOpen}
								onClose={() => {
									setIsStatusChangeFormOpen(false);
								}}
								onSuccess={() => {
									refetchAppraisalDetails();
									refetchAppraisalUsers();
									setIsStatusChangeFormOpen(false);
									clearSelectedRowsAndData()
								}}
								selectedUsersId={selectedRowData.map((usr) => usr.userId)}
								appraisalCycleId={id}
							/>
							<GoalMappingForm
								open={isGoalMappingFormShown}
								onClose={() => {
									setIsGoalMappingFormShown(false);
								}}
								onSuccess={() => {
									setSearchedText('');
									refetchAppraisalDetails();
									refetchAppraisalUsers();
									clearSelectedRowsAndData();
									setIsGoalMappingFormShown(false);
								}}
								selectedUserIds={selectedRowData.map((usr) => usr.userId)}
								appraisalCycleId={id}
								assignedGoalIds={selectedRowData
									.map((row) => row.assignedGoalIds)
									.flat()}
								allocatedGoalWeights={selectedRowData
									.map((row) => row.allocated_weight)
									.flat()}
								initialGoalStatus={head(appraisalUsers?.goalInitialStatus)?.id}
							/>
							<ViewGoals
								open={isViewGoalsModalShown}
								onClose={() => setIsViewGoalsModalShown(false)}
								userDetails={selectedViewGoals.userDetails}
								goals={selectedViewGoals.goals || []}
								refetch={() => {
									refetchAppraisalUsers();
								}}
								disabled={false}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default AppraisalDetails;
