import { head, isEqual } from 'lodash';
import {
	GetMyAppraisalDetailsQuery,
	GetReporteeAppraisalDetailsQuery,
} from '../../generated/graphql';
import { GoalReview, SelfReviewTableData, TableData } from './pms.module';
import {
	NUMBER_WITH_ONE_DECIMAL,
	PMS_APPRAISAL_STATUS,
	PMS_USER_APPRAISAL_STATUS,
	VALUE_WITH_IN_0_TO_100,
} from './constant';

export const isPristine = (
	initialRatingValues: GoalReview[],
	currentTableRatingValues: GoalReview[]
): boolean => {
	return isEqual(initialRatingValues, currentTableRatingValues);
};

export const loadGoalsForManagerReview = (
	data: GetReporteeAppraisalDetailsQuery
): TableData => {
	const isManagerReviewCompleted =
		head(data?.pms_pms_appraisal_user_mapping)?.pms_user_appraisal_status
			.value === PMS_USER_APPRAISAL_STATUS.APPRAISAL_MANAGER_REVIEW_COMPLETED ||
		head(data?.pms_pms_appraisal_user_mapping)?.pms_user_appraisal_status
			.value === PMS_USER_APPRAISAL_STATUS.APPRAISAL_CLOSED;

	const isAppraisalClosed =
		head(data?.appraisalCycle)?.pms_appraisal_status.value ===
		PMS_APPRAISAL_STATUS.APPRAISAL_CLOSED
			? true
			: false;

	const goals = data?.goals.map((goal, index) => ({
		goal: {
			id: goal.id,
			name: goal.pms_master_goal.name || '- -',
			description: goal.pms_master_goal.description || '- -',
		},
		userId: goal.user_id,
		assignedBy: goal.assigned_by,
		goalStatusId: goal.pms_goal_status.id,
		unit: goal.unit,
		startDate: goal.start_date,
		endDate: goal.end_date,
		target: `${goal?.target || 0}${goal.unit === 'percentage' ? '%' : ''}`,
		target_achieved: `${goal?.target_achieved || 0}${
			goal.unit === 'percentage' ? '%' : ''
		}`,
		weightage: goal.weightage || 0,
		selfReview: {
			isDisabled: true,
			isError: false,
			goalMappingId: goal.id,
			value: goal.progress || 0,
			errorText: '',
		},
		managerReview: {
			isDisabled: isManagerReviewCompleted,
			isError: false,
			goalMappingId: goal.id,
			value: goal.manager_review || 0,
			errorText: '',
		},
		checkins: goal.checkIns.map((checkIn) => ({
			progress: checkIn.progress,
			comment: checkIn.comment,
			doc_url: checkIn.doc_url,
		})),
		action: {
			id: goal.id,
			user_comment: goal.user_comment || '',
			reviewer_comment: goal.reviewer_comment || '',
			isDisabled: isManagerReviewCompleted,
			goal: goal.pms_master_goal.name || '- -',
			index: index,
		},
	}));

	return {
		isDisabled: isAppraisalClosed,
		isSubmissionHidden: isManagerReviewCompleted,
		isSaved: true,
		data: goals,
	};
};

export const updateGoalRatingByManager = (
	state: TableData,
	goalMappingId: string,
	value: string,
	initialManagerReview: GoalReview[]
): TableData => {
	const trimmedValue = Number(value);

	let updatedObj = {
		isError: false,
		value: trimmedValue,
		errorText: '',
	};

	if (value === '') {
		updatedObj = {
			...updatedObj,
			value: 0,
		};

		const updatedState = {
			...state,
			isSaved: false,
			data: state.data.map((goalMapping) => {
				return goalMappingId === goalMapping.managerReview.goalMappingId
					? {
							...goalMapping,
							managerReview: {
								...goalMapping.managerReview,
								...updatedObj,
								value: '0',
							},
					  }
					: { ...goalMapping };
			}),
		};

		const isBothInitAndCurrentDataSame: boolean = isPristine(
			initialManagerReview,
			updatedState.data.map((goalMapping) => goalMapping.managerReview)
		);

		return { ...updatedState, isSaved: isBothInitAndCurrentDataSame };
	}

	if (Number(trimmedValue) < 0 || Number(trimmedValue) > 100) {
		updatedObj = {
			...updatedObj,
			isError: true,
			errorText: VALUE_WITH_IN_0_TO_100,
		};
	}

	// TODO Need to confirm and remove once feature is released
	// if (!NUMBER_WITH_ONE_DECIMAL.test(trimmedValue)) {
	// 	updatedObj = {
	// 		...updatedObj,
	// 		isError: true,
	// 		errorText: 'Invalid',
	// 	};
	// }

	const updatedState = {
		...state,
		isSaved: false,
		data: state.data.map((goalMapping) => {
			return goalMappingId === goalMapping.managerReview.goalMappingId
				? {
						...goalMapping,
						managerReview: {
							...goalMapping.managerReview,
							...updatedObj,
							value: updatedObj.value,
						},
				  }
				: { ...goalMapping };
		}),
	};

	const isBothInitAndCurrentDataSame: boolean = isPristine(
		initialManagerReview,
		updatedState.data.map((goalMapping) => goalMapping.managerReview)
	);

	return { ...updatedState, isSaved: isBothInitAndCurrentDataSame };
};

export const loadGoalsForSelfReview = (
	data: GetMyAppraisalDetailsQuery
): SelfReviewTableData => {
	const isSelfReviewCompleted =
		head(data?.pms_pms_appraisal_user_mapping)?.pms_user_appraisal_status
			.value !== PMS_USER_APPRAISAL_STATUS.APPRAISAL_INITIATED;

	const isAppraisalClosed =
		head(data.appraisalCycle)?.pms_appraisal_status.value ===
		PMS_APPRAISAL_STATUS.APPRAISAL_CLOSED
			? true
			: false;

	const goals = data?.goals.map((goal, index) => ({
		goal: {
			id: goal.id,
			name: goal.pms_master_goal.name || '- -',
			description: goal.pms_master_goal.description || '- -',
		},
		userId: goal.user_id,
		assignedBy: goal.assigned_by,
		goalStatusId: goal.pms_goal_status.id,
		unit: goal.unit,
		startDate: goal.start_date,
		endDate: goal.end_date,
		target: `${goal?.target || 0}${goal.unit === 'percentage' ? '%' : ''}`,
		target_prefix: goal.target_prefix,
		target_achieved: {
			isDisabled: isSelfReviewCompleted,
			isError: false,
			goalMappingId: goal.id,
			value: `${goal?.target_achieved}` || '0',
			errorText: '',
			target_unit: goal.unit || '',
		},
		weightage: goal.weightage || 0,
		selfReview: {
			isDisabled: isSelfReviewCompleted,
			isError: false,
			goalMappingId: goal.id,
			value: goal.progress || 0,
			errorText: '',
		},
		checkins: goal.checkIns.map((checkIn) => ({
			progress: checkIn.progress,
			comment: checkIn.comment,
			doc_url: checkIn.doc_url,
		})),
		action: {
			id: goal.id,
			user_comment: goal.user_comment || '',
			isDisabled: isSelfReviewCompleted,
			goal: goal.pms_master_goal.name || '- -',
			index: index,
		},
	}));

	return {
		isDisabled: isAppraisalClosed,
		isSubmissionHidden: isSelfReviewCompleted,
		isSaved: true,
		data: goals,
	};
};

export const updateGoalRatingByUser = (
	state: SelfReviewTableData,
	goalMappingId: string,
	value: string,
	initialSelfReview: GoalReview[],
	initialTargetAchievedValues: GoalReview[]
): SelfReviewTableData => {
	const trimmedValue = Number(value);

	let updatedObj = {
		isError: false,
		value: trimmedValue,
		errorText: '',
	};

	if (value === '') {
		updatedObj = {
			...updatedObj,
			value: 0,
		};

		const updatedState = {
			...state,
			isSaved: false,
			data: state.data.map((goalMapping) => {
				return goalMappingId === goalMapping.selfReview.goalMappingId
					? {
							...goalMapping,
							selfReview: {
								...goalMapping.selfReview,
								...updatedObj,
								value: 0,
							},
					  }
					: { ...goalMapping };
			}),
		};

		const isBothInitAndCurrentSelfReviewSame: boolean = isPristine(
			initialSelfReview,
			updatedState.data.map((goalMapping) => goalMapping.selfReview)
		);

		const isBothInitAndCurrentTargetAchievedSame: boolean = isPristine(
			initialTargetAchievedValues,
			updatedState.data.map((goalMapping) => goalMapping.target_achieved)
		);
		return {
			...updatedState,
			isSaved:
				isBothInitAndCurrentSelfReviewSame &&
				isBothInitAndCurrentTargetAchievedSame,
		};
	}

	if (Number(trimmedValue) < 0 || Number(trimmedValue) > 100) {
		updatedObj = {
			...updatedObj,
			isError: true,
			errorText: VALUE_WITH_IN_0_TO_100,
		};
	}

	// TODO Need to confirm and remove once feature is released
	// if (!NUMBER_WITH_ONE_DECIMAL.test(trimmedValue)) {
	// 	updatedObj = {
	// 		...updatedObj,
	// 		isError: true,
	// 		errorText: 'Invalid',
	// 	};
	// }

	const updatedState = {
		...state,
		isSaved: false,
		data: state.data.map((goalMapping) => {
			return goalMappingId === goalMapping.selfReview.goalMappingId
				? {
						...goalMapping,
						selfReview: {
							...goalMapping.selfReview,
							...updatedObj,
							value: updatedObj.value,
						},
				  }
				: { ...goalMapping };
		}),
	};

	const isBothInitAndCurrentSelfReviewSame: boolean = isPristine(
		initialSelfReview,
		updatedState.data.map((goalMapping) => goalMapping.selfReview)
	);

	const isBothInitAndCurrentTargetAchievedSame: boolean = isPristine(
		initialTargetAchievedValues,
		updatedState.data.map((goalMapping) => goalMapping.target_achieved)
	);

	return {
		...updatedState,
		isSaved:
			isBothInitAndCurrentSelfReviewSame &&
			isBothInitAndCurrentTargetAchievedSame,
	};
};

export const updateTargetAchievedByUser = (
	state: SelfReviewTableData,
	goalMappingId: string,
	value: string,
	initialTargetAchievedValues: GoalReview[],
	initialSelfReview: GoalReview[]
): SelfReviewTableData => {
	const trimmedValue = value.trim();

	let updatedObj = {
		isError: false,
		value: trimmedValue.substring(0, 4),
		errorText: '',
	};

	if (trimmedValue === '') {
		updatedObj = {
			...updatedObj,
			value: '0',
		};

		const updatedState = {
			...state,
			isSaved: false,
			data: state.data.map((goalMapping) => {
				return goalMappingId === goalMapping.target_achieved.goalMappingId
					? {
							...goalMapping,
							target_achieved: {
								...goalMapping.target_achieved,
								...updatedObj,
								value: 0,
							},
					  }
					: { ...goalMapping };
			}),
		};

		const isBothInitAndCurrentSelfReviewSame: boolean = isPristine(
			initialSelfReview,
			updatedState.data.map((goalMapping) => goalMapping.selfReview)
		);

		const isBothInitAndCurrentTargetAchievedSame: boolean = isPristine(
			initialTargetAchievedValues,
			updatedState.data.map((goalMapping) => goalMapping.target_achieved)
		);

		return {
			...updatedState,
			isSaved:
				isBothInitAndCurrentSelfReviewSame &&
				isBothInitAndCurrentTargetAchievedSame,
		};
	}

	if (Number(trimmedValue) < 0 || Number(trimmedValue) > 100) {
		updatedObj = {
			...updatedObj,
			isError: true,
			errorText: VALUE_WITH_IN_0_TO_100,
		};
	}

	if (!NUMBER_WITH_ONE_DECIMAL.test(trimmedValue)) {
		updatedObj = {
			...updatedObj,
			isError: true,
			errorText: 'Invalid',
		};
	}

	const updatedState = {
		...state,
		isSaved: false,
		data: state.data.map((goalMapping) => {
			return goalMappingId === goalMapping.target_achieved.goalMappingId
				? {
						...goalMapping,
						target_achieved: {
							...goalMapping.target_achieved,
							...updatedObj,
							value: updatedObj.value,
						},
				  }
				: { ...goalMapping };
		}),
	};

	const isBothInitAndCurrentSelfReviewSame: boolean = isPristine(
		initialSelfReview,
		updatedState.data.map((goalMapping) => goalMapping.selfReview)
	);

	const isBothInitAndCurrentTargetAchievedSame: boolean = isPristine(
		initialTargetAchievedValues,
		updatedState.data.map((goalMapping) => goalMapping.target_achieved)
	);

	return {
		...updatedState,
		isSaved:
			isBothInitAndCurrentSelfReviewSame &&
			isBothInitAndCurrentTargetAchievedSame,
	};
};
